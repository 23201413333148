/* beige code  #F5F5DC */
/* dark code   #282c34 */

.App {
  text-align: center;
  font-family: "Roboto", cursive;
}

body {
  overflow-y: scroll;
  background-color: rgba(200, 200, 200, 0.1);
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: rgba(88, 115, 141, 0.7);
  border-radius: 20px;
}

img {
  pointer-events: none;
}

* {
  scrollbar-color: rgba(0, 0, 0, 0.2) hsla(0, 0%, 100%, 0.1);
  scrollbar-width: thin;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
